<template>
    <div class="grid grid-cols-12">
        <div class="col-span-12 md:col-span-6 opacity-85 font-4xl text-gray-800 font-titillium-bold ml-30 mb-20 mt-30 md:pl-20">
            {{$t('servicesPageTitle')}}
        </div>
        <div class="col-span-12 md:col-span-6 flex md:justify-end md:items-center mr-30 pr-20 relative ml-30 mb-30 md:mb-0 md:ml-0">
            <Button
                v-if="userTypeManager"
                @btnClick="showAddModal = true, charactersLeft = 200, eraseErrors(), addServiceObject = {
                    name: '',
                    measurement_unit: '',
                    total: '',
                    description: ''
                }"
                class="button bg-purple-500 text-white hover:bg-purple-600 w-257"
                role="submit"
                :text="$t('servicesPageAddServiceButtonText')"
            />
            <!-- Confirmation box for success messages -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <!-- Own services -->
        <div
            class="col-span-12 mx-30 bg-white rounded-lg shadow"
            :class="[allServices.length ? 'pb-30' : 'pb-0']"
        >
            <v-table
                v-if="toggleChecked.length !== 0"
                class="table"
                :pageSize="pageSize"
                :hideSortIcons="true"
                :data="allServices"
                :currentPage.sync="currentPage1"
                @totalPagesChanged="totalPages1 = $event"
            >
                <tbody
                    id="table_body1"
                    class="tbody grid grid-cols-12"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class='col-span-12 xl:col-span-6 pr-20 border-b'
                        :class="[((index % 2) === 0) ? ('xl:border-r') : ('')]"
                        v-for="(row, index) in displayData"
                        :key="row.id"
                    >
                        <td class="block">
                            <!-- Service details plus action buttons (edit, delete) -->
                            <div :class="[userTypeManager ? 'mb-30' : '']">
                                <div class="flex justify-between items-center mb-10">
                                    <p class="font-2xl2 text-gray-600">{{row.name}}</p>
                                    <div
                                        v-if="userTypeManager"
                                        class="flex"
                                    >
                                        <div @click='openEditModal(row, index), charactersLeft = textareaMaxCharacters - row.description.length, disabledEditSaveButton = true'>
                                            <img
                                                class="mr-4 cursor-pointer"
                                                :src="require('@/assets/images/icons/edit.svg')"
                                                alt="service_edit_icon"
                                                width="25"
                                            />
                                        </div>
                                        <div @click='openDeleteModal(row)'>
                                            <img
                                                class="cursor-pointer"
                                                :src="require('@/assets/images/icons/close.svg')"
                                                alt="service_delete_icon"
                                                width="25"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p class="text-gray-800 font-titillium-normal">
                                    {{row.description}}
                                </p>
                            </div>
                            <!-- Availability, usage and toggle for sharing -->
                            <div
                                v-if="userTypeManager"
                                class="flex flex-col lg:flex-row justify-between item-center relative"
                            >
                                <div class="flex mb-30 lg:mb-0">
                                    <div class="mr-30">
                                        <p class="font-4xl text-indigo-400">{{row.total}} {{row.measurement_unit}}</p>
                                        <p class="text-gray-500 font-titillium-normal">{{$t('servicesAvailability')}}</p>
                                    </div>
                                    <div>
                                        <p
                                            class="font-4xl"
                                            :class="[row.allocated_amount > row.total ? 'text-red-500' : 'text-green-500']"
                                        >{{row.allocated_amount }} {{row.measurement_unit}}</p>
                                        <p class="text-gray-500 font-titillium-normal">{{$t('servicesUsed')}}</p>
                                    </div>
                                </div>
                                <div class="flex items-center">
                                    <Toggle
                                        @input="unShareService(row.id, toggleChecked[index + (currentPage1-1)*pageSize].checked, index, row.shared_amount)"
                                        class="pt-7"
                                        :id="row.id"
                                        v-model="toggleChecked[index + (currentPage1 - 1) * pageSize].checked"
                                        :checked="row.shared_amount > 0"
                                    />
                                    <p
                                        class="font-titillium-normal text-gray-800"
                                        v-if="row.shared_amount > 0"
                                    >
                                        <span
                                            @click="toggleChecked[index + (currentPage1 - 1) * pageSize].checked = true; getInitSharedAmount(row)"
                                            class="cursor-pointer font-titillium-bold text-indigo-400"
                                        >
                                            {{row.shared_amount}} {{row.measurement_unit}}
                                        </span>

                                        {{$t('servicesShareTextShared')}}
                                    </p>
                                    <p
                                        v-else
                                        class="font-titillium-normal text-gray-400"
                                    >
                                        {{$t('servicesShareTextInit')}}
                                    </p>
                                </div>
                                <div
                                    v-if="toggleChecked[toggleChecked.findIndex(x => { return x.id.toString() === row.id.toString() })] && toggleChecked[toggleChecked.findIndex(x => { return x.id.toString() === row.id.toString() })].checked && getInitSharedAmount(row)"
                                    class="absolute bg-white rounded-lg p-10 w-257 shadow-custom right-100 top-115 lg:top-40 xl:top-72 lg:right-0 z-10"
                                >
                                    <div class="flex">
                                        <p class="text-sm mb-10 font-titillium-normal text-justify">
                                            {{$t('shareServiceTitle')}}
                                        </p>
                                        <button
                                            @click="closeModalAndToggle(row); toggleChecked[index + (currentPage1-1)*pageSize].checked = false"
                                            class="ml-10 flex items-start justify-end focus:outline-none"
                                        >
                                            <img
                                                class="w-16 h-16"
                                                :src="require('@/assets/images/icons/close_no_border.svg')"
                                                alt=""
                                            >
                                        </button>
                                    </div>
                                    <form
                                        @submit.prevent="onServiceShare(row)"
                                        ref="serviceShareForm"
                                    >
                                        <div class="flex items-center">
                                            <Input
                                                :error="errors.shared_amount"
                                                v-model="row.shared_amount"
                                                class="mr-10"
                                                :class="[errors.shared_amount ? 'mb-4' : 'mb-7']"
                                                label=''
                                                :placeholder="$t('addHoursText')"
                                                type="number"
                                                min="0"
                                                name="hours"
                                            />
                                            <Button
                                                class="button bg-purple-500 text-white hover:bg-purple-600 w-150"
                                                role="submit"
                                                :text="$t('saveButtonText')"
                                                :disabled="shareServiceLoading"
                                                :spinning="shareServiceLoading"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-table
                v-else
                class="table border-none"
                :data="allServices"
            >
                <tbody
                    class="tbody grid grid-cols-12"
                    slot="body"
                >
                    <tr
                        class="col-span-12"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noServicesText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- pagination for the table -->
            <div v-if="allServices.length" class="flex items-center justify-between pt-30">
                <div class="pl-10 p text-gray-800">{{pageServices}}-{{pageSizeIncrementServices}} {{ $t('fromText') }} {{allServices.length}} {{allServices.length > 1 ? $t('fromServicesText') : $t('fromServicesTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage1"
                        :totalPages="totalPages1"
                    />
                </div>
            </div>
        </div>
        <!-- Requested services -->
        <div class="col-span-6 ml-30 mb-20 mt-30 md:pl-20 font-4xl opacity-85 text-gray-800 font-titillium-bold">
            {{$t('requestedServicesTitlte')}}
        </div>
        <div
            class="col-span-12 mx-30 bg-white rounded-lg shadow"
            :class="[inProgressRequests.length ? 'pb-30' : 'pb-0']"
        >
            <v-table
                v-if="inProgressRequestsLoading.length !== 0"
                class="table"
                :pageSize="pageSize"
                :hideSortIcons="true"
                :data="inProgressRequests"
                :currentPage.sync="currentPage2"
                @totalPagesChanged="totalPages2 = $event"
            >
                <tbody
                    id="table_body2"
                    class="tbody grid grid-cols-12"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class='col-span-12 xl:col-span-6 pr-20 border-b'
                        :class="[((index % 2) === 0) ? ('xl:border-r') : ('')]"
                        v-for="(row, index) in displayData"
                        :key="row.id"
                    >
                        <td class="block">
                            <!-- Service details and cancel button -->
                            <div :class="[userTypeManager ? 'mb-30' : '']">
                                <p class="font-2xl2 text-gray-600 font-titillium-normal mb-10">{{row.name}}</p>
                                <p class="text-gray-800 font-titillium-normal">
                                    {{row.description}}
                                </p>
                            </div>
                            <div
                                class="flex justify-between items-center"
                                v-if="userTypeManager"
                            >
                                <div class="flex">
                                    <div class="mr-30">
                                        <p class="font-4xl text-indigo-400">{{row.total}} {{row.measurement_unit}}</p>
                                        <p class="text-gray-500 font-titillium-normal">{{$t('requestedServicesAmount')}}</p>
                                    </div>
                                </div>
                                <div class="flex">
                                    <p class="flex items-center font-lg-bold text-emerald-500 px-20 py-8 mr-8">{{$t('requestedServicesInProgressText')}}</p>
                                    <Button
                                        @click.native="discardRequest(row, 'inProgress')"
                                        class="button w-170 bg-red-500 text-white focus:bg-red-500 hover:bg-red-600 mb-5 font-bold"
                                        role="button"
                                        :text="$t('requestedServicesCancelButtonText')"
                                        :disabled="inProgressRequestsLoading[inProgressRequestsLoading.findIndex(x => { return x.id === row.id })].spinning"
                                        :spinning="inProgressRequestsLoading[inProgressRequestsLoading.findIndex(x => { return x.id === row.id })].spinning"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-table
                v-else
                class="table border-none"
                :data="inProgressRequests"
            >
                <tbody
                    class="tbody grid grid-cols-12"
                    slot="body"
                >
                    <tr
                        class="col-span-12"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noServicesText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- Pagination for the requested services -->
            <div v-if="inProgressRequests.length" class="flex items-center justify-between pt-30">
                <div class="pl-10 p text-gray-800">{{pageInProgress}}-{{pageSizeIncrementInProgress}} {{ $t('fromText') }} {{inProgressRequests.length}} {{ inProgressRequests.length > 1 ? $t('fromServicesText') : $t('fromServicesTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage2"
                        :totalPages="totalPages2"
                    />
                </div>
            </div>
        </div>
        <!-- Received services after request -->
        <div class="col-span-6 font-4xl opacity-85 text-gray-800 font-titillium-bold ml-30 mb-20 mt-30 pl-20">
            {{$t('receivedServicesTitle')}}
        </div>
        <div
            class="col-span-12 mx-30 bg-white rounded-lg shadow"
            :class="[receivedServices.length ? 'pb-30' : 'pb-0']"
        >
            <v-table
                v-if="receivedServicesLoading.length !== 0"
                class="table"
                :pageSize="pageSize"
                :hideSortIcons="true"
                :data="receivedServices"
                :currentPage.sync="currentPage3"
                @totalPagesChanged="totalPages3 = $event"
            >
                <tbody
                    id="table_body3"
                    class="tbody grid grid-cols-12"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <tr
                        class='col-span-12 xl:col-span-6 pr-20 border-b'
                        :class="[((index % 2) === 0) ? ('xl:border-r') : ('')]"
                        v-for="(row, index) in displayData"
                        :key="row.id"
                    >
                        <td class="block">
                            <!-- Service details and cancel button -->
                            <div :class="[userTypeManager ? 'mb-30' : '']">
                                <p class="font-2xl2 text-gray-600 font-titillium-normal mb-10">{{row.name}}</p>
                                <p class="text-gray-800 font-titillium-normal">
                                    {{row.description}}
                                </p>
                            </div>
                            <div
                                class="flex justify-between items-center"
                                v-if="userTypeManager"
                            >
                                <div class="flex">
                                    <div class="mr-30">
                                        <p class="font-4xl font-titillium-bold text-indigo-400">{{row.total}} {{row.measurement_unit}}</p>
                                        <p class="text-gray-500 font-titillium-normal">{{$t('receivedServicesAmount')}}</p>
                                    </div>
                                </div>
                                <Button
                                    @click.native="discardRequest(row, 'received')"
                                    class="button w-170 bg-red-500 text-white focus:bg-red-500 hover:bg-red-600 mb-5 font-bold"
                                    role="button"
                                    :text="$t('requestedServicesCancelButtonText')"
                                    :disabled="receivedServicesLoading[receivedServicesLoading.findIndex(x => { return x.id === row.id })].spinning"
                                    :spinning="receivedServicesLoading[receivedServicesLoading.findIndex(x => { return x.id === row.id })].spinning"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-table
                v-else
                class="table border-none"
                :data="receivedServices"
            >
                <tbody
                    class="tbody grid grid-cols-12"
                    slot="body"
                >
                    <tr
                        class="col-span-12"
                    >
                        <td class="font-titillium-normal text-gray-800">
                            {{$t('noServicesText')}}
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- Pagination for the requested and received services -->
            <div v-if="receivedServices.length" class="flex items-center justify-between pt-30">
                <div class="pl-10 p text-gray-800">{{pageReceived}}-{{pageSizeIncrementReceived}} {{ $t('fromText') }} {{receivedServices.length}} {{receivedServices.length > 1 ? $t('fromServicesText') : $t('fromServicesTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage3"
                        :totalPages="totalPages3"
                    />
                </div>
            </div>
        </div>
        <!-- Add service modal -->
        <Modal
            width='w-768'
            v-if="showAddModal"
            @close="verifyIfInputAddService(addServiceObject)"
            @closeOnEscPressed="verifyIfInputAddService(addServiceObject)"
        >
            <div slot="title">
                <h2 class="font-4xl font-titillium-bold text-gray-800">{{$t('addServiceTitle')}}</h2>
            </div>
            <p
                class="my-30 p text-gray-800 font-titillium-normal"
                slot="subtitle"
            >
                {{$t('addServiceSubtitle')}}
            </p>
            <div slot="body">
                <form
                    @submit.prevent="onAddServiceSubmit"
                    ref="addServiceForm"
                >
                    <div class="grid grid-cols-4 gap-x-30">
                        <Input
                            v-model="addServiceObject.name"
                            :error="errors.name"
                            class="col-span-2"
                            :class="[errors.name ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceNameLabel')"
                            :placeholder="$t('addServiceNamePlaceholder')"
                            type="text"
                            name="name"
                            isRequired="true"
                        />
                        <Input
                            v-model="addServiceObject.measurement_unit"
                            :error="errors.measurement_unit"
                            class="col-span-1"
                            :class="[errors.measurement_unit ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceMeasurementUnitLabel')"
                            :placeholder="$t('addServiceMeasurementUnitPlaceholder')"
                            type="text"
                            name="measurement_unit"
                            isRequired="true"
                        />
                        <Input
                            v-model="addServiceObject.total"
                            :error="errors.total"
                            class="col-span-1"
                            :class="[errors.total ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceAvailabilityLabel')"
                            :placeholder="$t('addServiceAvailabilityPlaceholder')"
                            type="number"
                            name="total"
                            isRequired="true"
                        />
                        <div class="relative col-span-4 mb-30">
                            <Textarea
                                v-model="addServiceObject.description"
                                :error="errors.description"
                                :class="[errors.description ? 'mb-4' : '']"
                                v-on:input="remainingCharacters"
                                :maxlength="textareaMaxCharacters"
                                :label="$t('addServiceDescriptionLabel')"
                                name="description"
                                :placeholder="$t('addServiceDescriptionPlaceholder')"
                            />
                            <span class="absolute bottom-10 right-10 small-bold">{{charactersLeft}}/{{textareaMaxCharacters}}</span>
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <Button
                            class="button w-257 bg-purple-500 text-white hover:bg-purple-600 mb-5 font-bold"
                            role="submit"
                            :text="$t('addServiceButtonText')"
                            :disabled="addServiceLoading"
                            :spinning="addServiceLoading"
                        />
                    </div>
                </form>
            </div>
        </Modal>
        <!-- Edit service modal -->
        <Modal
            width='w-768'
            v-if="showEditModal"
            @close="verifyIfInputEditService(editService)"
            @closeOnEscPressed="verifyIfInputEditService(editService)"
        >
            <div slot="title">
                <h2 class="font-4xl">{{$t('editServiceTitle')}}</h2>
            </div>
            <p
                class="my-30 p text-gray-800"
                slot="subtitle"
            >
                {{$t('editServiceSubtitle')}}
            </p>
            <div slot="body">
                <form
                    @submit.prevent="onEditServiceSubmit"
                    @input="activateEditSaveButton"
                    ref="editServiceForm"
                >
                    <div class="grid grid-cols-4 gap-x-30">
                        <Input
                            v-model="editServiceCopy.name"
                            :error="errors.name"
                            class="col-span-2"
                            :class="[errors.name ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceNameLabel')"
                            :placeholder="$t('addServiceNamePlaceholder')"
                            type="text"
                            name="name"
                        />
                        <Input
                            v-model="editServiceCopy.measurement_unit"
                            :error="errors.measurement_unit"
                            class="col-span-1"
                            :class="[errors.measurement_unit ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceMeasurementUnitLabel')"
                            :placeholder="$t('addServiceMeasurementUnitPlaceholder')"
                            type="text"
                            name="measurement_unit"
                        />
                        <Input
                            v-model="editServiceCopy.total"
                            :error="errors.total"
                            class="col-span-1"
                            :class="[errors.total ? 'mb-4' : 'mb-20']"
                            :label="$t('addServiceAvailabilityLabel')"
                            :placeholder="$t('addServiceAvailabilityPlaceholder')"
                            type="number"
                            name="total"
                        />
                        <div class="relative col-span-4 mb-30">
                            <Textarea
                                v-model="editServiceCopy.description"
                                :error="errors.description"
                                :class="[errors.description ? 'mb-4' : '']"
                                v-on:input="remainingCharacters"
                                :maxlength="textareaMaxCharacters"
                                :label="$t('addServiceDescriptionLabel')"
                                name="description"
                                :placeholder="$t('addServiceDescriptionPlaceholder')"
                            />
                            <span class="absolute bottom-10 right-10 small-bold">{{charactersLeft}}/{{textareaMaxCharacters}}</span>
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <Button
                            @btnClick="openDeleteModal(editServiceCopy)"
                            class="button w-257 bg-red-500 text-white focus:bg-red-500 hover:bg-red-600 mb-5 font-bold"
                            role="button"
                            :text="$t('editServiceDeleteButtonText')"
                        />
                        <Button
                            :disabled="disabledEditSaveButton || editServiceLoading"
                            class="button w-257 bg-purple-500 text-white hover:bg-purple-600 mb-5 font-bold"
                            role="submit"
                            :text="$t('editServiceButtonText')"
                            :spinning="editServiceLoading"
                        />
                    </div>
                </form>
            </div>
        </Modal>
        <!-- Delete service modal -->
        <Modal
            width='w-420'
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @closeOnEscPressed="showDeleteModal = false"
        >
            <div
                slot="title"
                class="mb-30"
            >
                <h2 class="font-4xl">{{$t('deleteServiceTitle')}}</h2>
            </div>
            <div slot="body">
                <p class="font-titillium-normal mb-30">
                    {{$t('deleteServiceText')}} <span class="font-bold">{{deleteService.name}}</span>
                </p>
                <div class="flex justify-between">
                    <Button
                        @btnClick="showDeleteModal = false"
                        class="button w-170 bg-gray-500 text-white focus:bg-gray-500 hover:bg-gray-600 mb-5 font-bold"
                        role="button"
                        :text="$t('deleteServiceNoButtonText')"
                    />
                    <Button
                        @btnClick="onDeleteServiceSubmit"
                        class="button w-170 bg-red-500 text-white focus:bg-red-500 hover:bg-red-600 mb-5 font-bold"
                        role="button"
                        :text="$t('deleteServiceYesButtonText')"
                        :disabled="deleteServiceLoading"
                        :spinning="deleteServiceLoading"
                    />
                </div>
            </div>
        </Modal>
        <Modal
            width='w-420'
            v-if="showWarningModal"
            @close="showWarningModal = false"
            @closeOnEscPressed="showWarningModal = false"
        >
            <div
                slot="title"
                class="mb-30"
            >
                <h2 class="font-4xl">{{ $t('closeModalQuestionText') }}</h2>
            </div>
            <div slot="body">
                <div class="flex justify-between">
                    <Button
                        @btnClick="showWarningModal = false"
                        class="button w-170 bg-gray-500 text-white focus:bg-gray-500 hover:bg-gray-600 mb-5 font-bold"
                        role="button"
                        :text="$t('deleteServiceNoButtonText')"
                    >
                    </Button>
                    <Button
                        @btnClick="showWarningModal = false, showAddModal = false, showEditModal = false"
                        class="button w-170 bg-red-500 text-white focus:bg-red-500 hover:bg-red-600 mb-5 font-bold"
                        role="button"
                        :text="$t('deleteServiceYesButtonText')"
                    >
                    </Button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/buttons/Button'
import Toggle from '@/components/form/Toggle'
import Modal from '@/components/modal/Modal'
import Input from '@/components/form/Input'
import Textarea from '@/components/form/Textarea'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'AllServices',
    components: {
        Button,
        Toggle,
        Modal,
        Input,
        Textarea
    },
    data () {
        return {
            showAddModal: false,
            showEditModal: false,
            showDeleteModal: false,
            addServiceObject: {
                name: '',
                measurement_unit: '',
                total: '',
                description: ''
            },
            editService: null, /** the current service that will be edited */
            deleteService: null, /** the current service that will be deleted */
            toggleChecked: [],
            initSharedAmount: [], /** stores services initial shared amount value */
            sharedAvailability: 0,
            textareaMaxCharacters: 200, /** used for text area maximum characters */
            charactersLeft: 0,
            currentPage1: 1,
            totalPages1: 0,
            currentPage2: 1,
            totalPages2: 0,
            currentPage3: 1,
            totalPages3: 0,
            showWarningModal: false,
            editServiceCopy: null,
            disabledEditSaveButton: true,
            pageSize: 8,
            pageSizeIncrementServices: null,
            currentPageSizeServices: null,
            pageServices: null,
            pageSizeIncrementReceived: null,
            currentPageSizeReceived: null,
            pageReceived: null,
            pageSizeIncrementInProgress: null,
            currentPageSizeInProgress: null,
            pageInProgress: null,

            inProgressRequestsLoading: [],
            receivedServicesLoading: [],

            addServiceLoading: false,
            editServiceLoading: false,
            deleteServiceLoading: false,
            shareServiceLoading: false,
            discardRequestedServiceLoading: false,
            discardReceivedServiceLoading: false
        }
    },
    computed: {
        ...mapGetters(['allServices', 'userTypeManager', 'token', 'errors', 'error', 'addRequestStatus', 'editRequestStatus', 'deleteRequestStatus', 'shareRequestStatus', 'inProgressRequests', 'receivedServices', 'discardRequestStatus'])
    },
    methods: {
        ...mapActions(['addService', 'getServices', 'getSentRequests', 'getReceivedRequests', 'getRequestStatistics', 'editServiceRequest', 'deleteServiceRequest', 'eraseErrors', 'shareService', 'discardRequestService']),
        /** Function used to unshare a service from the network. */
        unShareService (id, checked, index, sharedAmount) {
            if (!checked && sharedAmount > 0) {
                const SuccessBoxComponent = Vue.extend(SuccessBox)

                const service = {
                    id: id,
                    shared_amount: 0
                }

                this.shareService({
                    token: this.token,
                    service
                })
                    .then(response => {
                        if (this.shareRequestStatus === true) {
                            /** Dynamically mount success box to page */
                            const statusMessage = this.$t('servicesPageSharedRequestZeroSuccessBoxSubtitle')
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: statusMessage, success: true }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)

                            this.toggleChecked[index].checked = false
                        } else {
                            if (this.error !== '') {
                                const instance = new SuccessBoxComponent({
                                    propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                                })
                                instance.$mount()
                                this.$refs.successBoxContainer.appendChild(instance.$el)
                            }
                        }
                    })
            }
        },
        /** Function to check if any input change to ask for exit without save */
        verifyIfInputAddService (service) {
            if (service.name !== '' ||
                service.measurement_unit !== '' ||
                service.total !== '' ||
                service.description !== '') {
                this.showWarningModal = true
            } else {
                this.showAddModal = false
            }
        },
        verifyIfInputEditService (service) {
            if (service.name !== this.editServiceCopy.name ||
                service.description !== this.editServiceCopy.description ||
                service.total !== this.editServiceCopy.total ||
                service.measurement_unit !== this.editServiceCopy.measurement_unit) {
                this.showWarningModal = true
            } else {
                this.showEditModal = false
            }
        },
        activateEditSaveButton () {
            if (this.editService.name !== this.editServiceCopy.name ||
                this.editService.description !== this.editServiceCopy.description ||
                this.editService.total !== this.editServiceCopy.total ||
                this.editService.measurement_unit !== this.editServiceCopy.measurement_unit) {
                this.disabledEditSaveButton = false
            } else {
                this.disabledEditSaveButton = true
            }
        },
        /** The add service function. */
        onAddServiceSubmit () {
            this.addServiceLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.addService({ token: this.token, service: this.addServiceObject })
                .then(response => {
                    if (this.addRequestStatus === true) {
                        this.addServiceLoading = false
                        this.showAddModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('servicesPageAddRequestSuccessBoxSubtitle'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)

                        this.toggleChecked.unshift({ id: response.data.id, checked: false })
                    } else {
                        this.addServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        /** function for the text area to calculate the remaining characters. */
        remainingCharacters (event) {
            this.charactersLeft = this.textareaMaxCharacters - event.length
        },
        openEditModal (service, index) {
            this.editService = service
            this.editServiceCopy = JSON.parse(JSON.stringify(service))
            this.showEditModal = true
            this.eraseErrors()
        },

        /** Function to edit one service */
        onEditServiceSubmit () {
            this.editServiceLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const payload = {
                token: this.token,
                service: {
                    id: this.editService.id,
                    name: this.editServiceCopy.name,
                    description: this.editServiceCopy.description,
                    measurement_unit: this.editServiceCopy.measurement_unit,
                    total: this.editServiceCopy.total,
                    shared_amount: this.editService.shared_amount,
                    allocated_amount: this.editService.allocated_amount
                }
            }

            this.editServiceRequest(payload)
                .then(response => {
                    if (this.editRequestStatus === true) {
                        this.editServiceLoading = false
                        this.showEditModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('servicesPageEditRequestSuccessBoxSubtitle'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        this.editServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        openDeleteModal (service) {
            this.deleteService = service
            this.showDeleteModal = true
        },

        /** Function used to delete one service */
        onDeleteServiceSubmit () {
            this.deleteServiceLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const serviceToDelete = {
                service_id: this.deleteService.id
            }
            this.deleteServiceRequest({ token: this.token, service: serviceToDelete })
                .then(response => {
                    if (this.deleteRequestStatus === true) {
                        this.deleteServiceLoading = false
                        this.showDeleteModal = false
                        this.showEditModal = false

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('servicesPageDeleteRequestSuccessBoxSubtitle'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)

                        this.toggleChecked.map((service, index) => {
                            if (service.id === this.deleteService.id) {
                                this.toggleChecked.splice(index, 1)
                            }
                        })
                    } else {
                        this.deleteServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.eror, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },
        /** Share service post method. */
        onServiceShare (row) {
            this.shareServiceLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const service = {
                id: row.id,
                shared_amount: row.shared_amount
            }

            this.shareService({
                token: this.token,
                service
            })
                .then(response => {
                    if (this.shareRequestStatus === true) {
                        this.shareServiceLoading = false

                        /** Dynamically mount success box to page */
                        let statusMessage = this.$t('servicesPageSharedRequestSuccessBoxSubtitle')
                        if (service.shared_amount === '0' || service.shared_amount === '') {
                            statusMessage = this.$t('servicesPageSharedRequestZeroSuccessBoxSubtitle')
                        }
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: statusMessage, success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)

                        const index = this.toggleChecked.findIndex(x => {
                            return x.id.toString() === row.id.toString()
                        })
                        this.toggleChecked[index].checked = false

                        /** Clear initial shared amount values from array for that service. */
                        this.initSharedAmount.splice(row.id, 1)
                    } else {
                        this.shareServiceLoading = false
                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** FUnction to discard one of the received or requested services */
        discardRequest (row, list) {
            if (list === 'inProgress') {
                this.inProgressRequestsLoading[this.inProgressRequestsLoading.findIndex(x => { return x.id === row.id })].spinning = true
            } else {
                this.receivedServicesLoading[this.receivedServicesLoading.findIndex(x => { return x.id === row.id })].spinning = true
            }
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            const requestToUpdate = {
                id: row.id,
                status: 'discarded',
                list: list
            }

            this.discardRequestService({ token: this.token, request: requestToUpdate })
                .then(response => {
                    if (this.discardRequestStatus === true) {
                        if (list === 'inProgress') {
                            this.inProgressRequestsLoading[this.inProgressRequestsLoading.findIndex(x => { return x.id === row.id })].spinning = false

                            this.inProgressRequestsLoading.map((service, index) => {
                                if (service.id === row.id) {
                                    this.inProgressRequestsLoading.splice(index, 1)
                                }
                            })
                        } else {
                            this.receivedServicesLoading[this.receivedServicesLoading.findIndex(x => { return x.id === row.id })].spinning = false

                            this.receivedServicesLoading.map((service, index) => {
                                if (service.id === row.id) {
                                    this.receivedServicesLoading.splice(index, 1)
                                }
                            })
                        }

                        /** Dynamically mount success box to page */
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('servicesPageDiscardRequestSuccessBoxSubtitle'), success: true }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    } else {
                        if (list === 'inProgress') {
                            this.inProgressRequestsLoading[this.inProgressRequestsLoading.findIndex(x => { return x.id === row.id })].spinning = false
                        } else {
                            this.receivedServicesLoading[this.receivedServicesLoading.findIndex(x => { return x.id === row.id })].spinning = false
                        }

                        if (this.error !== '') {
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.eror, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Display info table in table footer method */
        getTableRecordsInfoServices () {
            this.currentPageSizeServices = document.getElementById('table_body1')?.childElementCount
            this.pageServices = (this.pageSize * (this.currentPage1 - 1)) + 1

            if (this.allServices.length < (this.pageSize * this.currentPage1)) {
                this.pageSizeIncrementServices = (this.pageSize * (this.currentPage1 - 1)) + this.currentPageSizeServices
            } else {
                this.pageSizeIncrementServices = (this.pageSize * this.currentPage1)
            }
        },

        /** Display info table in table footer method */
        getTableRecordsInfoInProgress () {
            this.currentPageSizeInProgress = document.getElementById('table_body2')?.childElementCount
            this.pageInProgress = (this.pageSize * (this.currentPage2 - 1)) + 1

            if (this.inProgressRequests.length < (this.pageSize * this.currentPage2)) {
                this.pageSizeIncrementInProgress = (this.pageSize * (this.currentPage2 - 1)) + this.currentPageSizeInProgress
            } else {
                this.pageSizeIncrementInProgress = (this.pageSize * this.currentPage2)
            }
        },

        /** Display info table in table footer method */
        getTableRecordsInfoReceived () {
            this.currentPageSizeReceived = document.getElementById('table_body3')?.childElementCount
            this.pageReceived = (this.pageSize * (this.currentPage3 - 1)) + 1

            if (this.receivedServices.length < (this.pageSize * this.currentPage3)) {
                this.pageSizeIncrementReceived = (this.pageSize * (this.currentPage3 - 1)) + this.currentPageSizeReceived
            } else {
                this.pageSizeIncrementReceived = (this.pageSize * this.currentPage3)
            }
        },
        /** Get the initial shared amount of a service, then store it. */
        getInitSharedAmount (row) {
            /** Store service initial shared amount if not exists in array. */
            if (!(row.id in this.initSharedAmount)) {
                this.initSharedAmount[row.id] = row.shared_amount
            }

            /** Return true to be used in v-if statement up above. */
            return true
        },
        /** Close mini-modal for share service and toggle off. */
        closeModalAndToggle (row) {
            /** Check if initial shared amount was = 0. */
            if (this.initSharedAmount[row.id] === 0) {
                /** Directly access DOM element to set checked to false. */
                document.getElementById(row.id).checked = false
                /** Set shared amount at initial value (0) (if a value was entered, that value was not saved) */
                row.shared_amount = this.initSharedAmount[row.id]
            } else {
                /** Restore shared amount at initial value. */
                row.shared_amount = this.initSharedAmount[row.id]
            }
        }
    },
    updated () {
        this.getTableRecordsInfoServices()
        this.getTableRecordsInfoInProgress()
        this.getTableRecordsInfoReceived()
    },
    async created () {
        /** Get services lists */
        await this.getServices({ token: this.token })
            .then(() => {
                for (const service in this.allServices) {
                    this.toggleChecked.push({ id: this.allServices[service].id, checked: false })
                }

                this.getTableRecordsInfoServices()
            })
        await this.getSentRequests({ token: this.token })
            .then(() => {
                for (const service in this.inProgressRequests) {
                    this.inProgressRequestsLoading.push({ id: this.inProgressRequests[service].id, spinning: false })
                }

                this.getTableRecordsInfoInProgress()
            })
        await this.getReceivedRequests({ token: this.token })
            .then(() => {
                for (const service in this.receivedServices) {
                    this.receivedServicesLoading.push({ id: this.receivedServices[service].id, spinning: false })
                }

                this.getTableRecordsInfoReceived()
            })

        await this.getRequestStatistics({ token: this.token })
    }
}
</script>
